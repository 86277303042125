import { Box } from "@mui/material"

import { AnswersType } from "../Models/AnswersType"
import { AnswerNumber } from "../Models/AnswerNumberType"

import AnswerSelectCard from "./AnswerSelectCard"

import { flexSpaceR } from "../Styles/EvaluationToolTheme"
import { evaluationStageType } from "../Models/EvaluationStageType"

const AnswerSelect = ({
    answerNumber,
    collectedAnswers,
    setCollectedAnswers,
    evaluationStage,
}: {
    answerNumber: AnswerNumber
    collectedAnswers: AnswersType
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>
    evaluationStage: evaluationStageType
}) => {
    return (
        <Box
            sx={{
                width: "90%",
                height: "100%",
                ...flexSpaceR,
                flexGrow: 0,
                overFlow: "hidden",
                mt: 4,
            }}
        >
            <AnswerSelectCard answerNumber={answerNumber} collectedAnswers={collectedAnswers} setCollectedAnswers={setCollectedAnswers} index={0} evaluationStage={evaluationStage} />
            <AnswerSelectCard answerNumber={answerNumber} collectedAnswers={collectedAnswers} setCollectedAnswers={setCollectedAnswers} index={1} evaluationStage={evaluationStage} />
            <AnswerSelectCard answerNumber={answerNumber} collectedAnswers={collectedAnswers} setCollectedAnswers={setCollectedAnswers} index={2} evaluationStage={evaluationStage} />
        </Box >
    )
}

export default AnswerSelect
