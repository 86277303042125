import { Box } from "@mui/material"
import { WithChildren } from "../Models/WithChildren"

import { flexSpaceC } from "../Styles/EvaluationToolTheme"

const QuestionAnswerBox: React.FC<WithChildren> = ({ children }) => {
    return (
        <Box
            id="tempQuestion-answer"
            sx={{
                width: "100%",
                height: "100%",
                ...flexSpaceC,
                my: 4,
            }}
        >
            {children}
        </Box>
    )
}

export default QuestionAnswerBox
