import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { ActivePage } from "./Models/ActivePageType"
import { QuestionTypes } from "./Models/QuestionTypes"
import { AnswersType } from "./Models/AnswersType"
import { evaluationStageType } from "./Models/EvaluationStageType"

import EvaluationStart from "./Pages/EvaluationStart"
/* import EvaluationChooseType from "./Pages/EvaluationChooseType" */
import EvaluationQuestion from "./Pages/EvaluationQuestion"
import EvaluationEnd from "./Pages/EvaluationEnd"
import { questionsObj } from "./Data/questions"
import MainLayout from "./Layouts/MainLayout"
import { ThemeProvider } from "@mui/material/styles"
import evaluationToolTheme from "./Styles/EvaluationToolTheme"

//Main Component for Evaluation Tool.

//Handles logic for tracking and cycling through pages, collecting answers, and rendering page elements.
const EvaluationTool = () => {
    const [activePage, setActivePage] = useState<ActivePage>("start")
    const [questionType, setQuestionType] = useState<QuestionTypes>(null)

    const [searchParams] = useSearchParams();
    const evaluationStage = searchParams.get("stage") as evaluationStageType;

    const [collectedAnswers, setCollectedAnswers] = useState<AnswersType>({});

    //This is used storing answers when looping a single question
    const [collectedAnswersCopy, setCollectedAnswersCopy] = useState<AnswersType>({});

    const setPageToStart = () => setActivePage("start")
    /*     const setPageToChooseType = () => setActivePage("chooseType") */
    const setPageToQuestionX = (qNum: number) => setActivePage(`question${qNum}`)
    const setPageToEnd = () => setActivePage("end")


    useEffect(() => {
        console.log(collectedAnswers, "collectedAnswersUpdated");
        console.log(collectedAnswersCopy, "collectedAnswersCopyUpdated")
    }, [collectedAnswersCopy])

    //This function decides which page will be rendered.
    const renderPage = (activePage: ActivePage) => {
        switch (true) {
            case activePage === "start":
                return <EvaluationStart evaluationStage={evaluationStage} setPageToQuestionX={setPageToQuestionX} />

            /*             case activePage === "chooseType":
                            return <EvaluationChooseType setPageToStart={setPageToStart} setPageToQuestionX={setPageToQuestionX} setQuestionType={setQuestionType} questionType={questionType} /> */


            case /^question\d+$/.test(activePage):
                return (
                    <EvaluationQuestion
                        activePage={activePage}
                        questionType={questionType}
                        collectedAnswers={collectedAnswers}
                        setPageToStart={setPageToStart}
                        setPageToQuestionX={setPageToQuestionX}
                        setPageToEnd={setPageToEnd}
                        setCollectedAnswers={setCollectedAnswers}
                        setQuestionType={setQuestionType}
                        evaluationStage={evaluationStage}
                    />
                )

            case activePage === "end":
                return <EvaluationEnd setPageToQuestionX={setPageToQuestionX} setCollectedAnswers={setCollectedAnswers} evaluationStage={evaluationStage} collectedAnswers={collectedAnswers} setCollectedAnswersCopy={setCollectedAnswersCopy} />
        }
    }

    return <ThemeProvider theme={evaluationToolTheme}><MainLayout>{renderPage(activePage)}</MainLayout></ThemeProvider>
}

export default EvaluationTool
