import StartContentBox from "../Components/StartContentBox"

import ImageUtvärdering from "../Assets/Images/Bildstöd/utvärdering.svg"
import ImageBörja from "../Assets/Images/Bildstöd/börja.svg"
import { evaluationStageType } from "../Models/EvaluationStageType"

//Start Page of the Evaluation Tool
const EvaluationStart = ({ evaluationStage, setPageToQuestionX, }: { evaluationStage: evaluationStageType, setPageToQuestionX: (qNum: number) => void }) => {

    let buttonText = "";

    if (evaluationStage === "before") {
        buttonText = "STARTA NY FÖREMÄTNING";

    } else if (evaluationStage === "after") {
        buttonText = "STARTA NY EFTERMÄTNING";

    } else if (evaluationStage === "full") {
        buttonText = "STARTA NY UTVÄRDERING";

    }

    return (
        <StartContentBox
            imageA={ImageBörja}
            imageB={ImageUtvärdering}
            imageAltA="börja"
            imageAltB="utvärdering"
            buttonText={buttonText}
            buttonAction={() => { setPageToQuestionX(1) }}
        />
    )
}

export default EvaluationStart
