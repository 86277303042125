import { Typography } from "@mui/material"
import { questionArrType, setQuestion } from "../Data/questions"
import { evaluationStageType } from "../Models/EvaluationStageType"

const Question = ({ question, evaluationStage }: { question: number | string, evaluationStage: evaluationStageType }) => {



    return <Typography variant="h1"
        sx={{
            border: "solid 3px #9bd64d",
            borderRadius: "40px",
            py: 8,
            width: "95%",
            textAlign: "center",
        }}>{setQuestion(question, evaluationStage)}</Typography >
}

export default Question
