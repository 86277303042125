import { createTheme, Theme } from "@mui/material/styles"

const evaluationToolTheme = createTheme({
    typography: {
        fontFamily: "'Raleway', 'sans-serif'",
        h1: {
            fontSize: "2.5rem"
        },
        h2: {
            fontSize: "2rem"
        }
    },

    palette: {
        primary: {
            main: "#9bd64d",
        }
    },

    components: {
        MuiButton: {
            //Cant figure out how to set MUI Icon styles other than globally for all icons,
            //Handled in SX props for now.
            styleOverrides: {
                root: {
                    color: "black"
                },
                containedPrimary: (button) => ({
                    backgroundColor: button.theme.palette.primary.main,
                    fontSize: "2.5rem"
                }),
                containedSecondary: (button) => ({
                    backgroundColor: button.theme.palette.primary.main,
                    fontSize: "1.75rem"
                }),
                containedError: (button) => ({
                    backgroundColor: "red",
                    fontSize: "1.75rem"
                }),
                outlined: {
                    fontSize: "1.5rem"
                }
            }
        }
    }
})

export default evaluationToolTheme

/* const theme = createTheme({
    typography: {
        fontFamily: "'Raleway', 'sans-serif'",
        h1: {
            "@media (max-width:600px)": { fontSize: "2rem" },  // xs (-20%)
            "@media (max-width:900px)": { fontSize: "2.25rem" },  // sm (-10%)
            "@media (min-width:900px) and (max-width:1199px)": { fontSize: "2.5rem" },  // md (explicit default)
            "@media (min-width:1200px) and (max-width:1535px)": { fontSize: "2.75rem" },  // lg (+10%)
            "@media (min-width:1536px)": { fontSize: "3rem" }  // xl (+20%)
        },
        h2: {
            "@media (max-width:600px)": { fontSize: "1.6rem" },
            "@media (max-width:900px)": { fontSize: "1.8rem" },
            "@media (min-width:900px) and (max-width:1199px)": { fontSize: "2rem" }, 
            "@media (min-width:1200px) and (max-width:1535px)": { fontSize: "2.2rem" }, 
            "@media (min-width:1536px)": { fontSize: "2.4rem" }
        }
    },

    palette: {
        primary: {
            main: "#9bd64d"
        }
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: "black"
                },
                containedPrimary: (button) => ({
                    backgroundColor: button.theme.palette.primary.main,
                    "@media (max-width:600px)": { fontSize: "2rem" },  // xs (-20%)
                    "@media (max-width:900px)": { fontSize: "2.25rem" },  // sm (-10%)
                    "@media (min-width:900px) and (max-width:1199px)": { fontSize: "2.5rem" },  // md (explicit)
                    "@media (min-width:1200px) and (max-width:1535px)": { fontSize: "2.75rem" },  // lg (+10%)
                    "@media (min-width:1536px)": { fontSize: "3rem" }  // xl (+20%)
                }),
                containedSecondary: (button) => ({
                    backgroundColor: button.theme.palette.primary.main,
                    "@media (max-width:600px)": { fontSize: "1.4rem" },
                    "@media (max-width:900px)": { fontSize: "1.6rem" },
                    "@media (min-width:900px) and (max-width:1199px)": { fontSize: "1.75rem" }, 
                    "@media (min-width:1200px) and (max-width:1535px)": { fontSize: "1.9rem" }, 
                    "@media (min-width:1536px)": { fontSize: "2.1rem" } 
                }),
                outlined: {
                    "@media (max-width:600px)": { fontSize: "1.2rem" },
                    "@media (max-width:900px)": { fontSize: "1.35rem" },
                    "@media (min-width:900px) and (max-width:1199px)": { fontSize: "1.5rem" }, 
                    "@media (min-width:1200px) and (max-width:1535px)": { fontSize: "1.65rem" }, 
                    "@media (min-width:1536px)": { fontSize: "1.8rem" } 
                }
            }
        }
    }
});
 */

export const flexCenterC = {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex"
}

export const flexSpaceC = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between"
}

export const flexCenterR = {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    display: "flex"
}

export const flexSpaceR = {
    justifyContent: "center",
    alignItems: "space-between",
    flexDirection: "row",
    display: "flex"
}
