import EndContentBox from "../Components/EndContentBox"
import BackForwardButtonBox from "../Components/BackForwardButtonBox"

import { updateAnswer } from "../Utilities/SetNewAnswer"
import { AnswersType } from "../Models/AnswersType"

import ImageTack from "../Assets/Images/Bildstöd/tack.svg"
import ImageBörja from "../Assets/Images/Bildstöd/börja.svg"
import { questionsObj } from "../Data/questions"
import { evaluationStageType } from "../Models/EvaluationStageType"

const EvaluationEnd = ({ setPageToQuestionX, setCollectedAnswers, evaluationStage, collectedAnswers, setCollectedAnswersCopy }: { setPageToQuestionX: (qNum: number) => void; setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>, evaluationStage: evaluationStageType, collectedAnswers: AnswersType, setCollectedAnswersCopy: React.Dispatch<React.SetStateAction<AnswersType>> }) => {

    let questionsArr;
    if (evaluationStage === "full") {
        questionsArr = questionsObj.full;
    } else {
        questionsArr = questionsObj.beforeAfter;
    }

    const pageAnswerIndex: number = questionsArr.length;

    return (
        <BackForwardButtonBox
            pageForward={() => { }}
            pageBackward={() => {

                setPageToQuestionX(pageAnswerIndex);
                updateAnswer({ [`answer${pageAnswerIndex}`]: null }, setCollectedAnswers)
            }}
            makeVisible={false}
        >
            <EndContentBox
                imageA={ImageBörja}
                imageB={ImageTack}
                imageAltA={"börja"}
                imageAltB={"tack"}
                buttonText={"NU ÄR DET SLUT, TACK FÖR IDAG"}
                buttonAction={() => { }}
                evaluationStage={evaluationStage}
                setPageToAnswerIndex={() => { setPageToQuestionX(pageAnswerIndex) }}
                collectedAnswers={collectedAnswers}
                setCollectedAnswersCopy={setCollectedAnswersCopy}
            />
        </BackForwardButtonBox>
    )
}


export default EvaluationEnd
