import { Box } from "@mui/material"
import { Button } from "@mui/material"

import { WithChildren } from "../Models/WithChildren"

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { flexSpaceC } from "../Styles/EvaluationToolTheme"

const BackForwardButtonBox: React.FC<
    WithChildren & {
        pageForward: () => void
        pageBackward: () => void
        makeVisible: boolean
    }
> = ({ children, pageForward, pageBackward, makeVisible }) => {
    const visibility = makeVisible === false ? "hidden" : "visible"

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                ...flexSpaceC
            }}
        >
            <Button
                onClick={() => {
                    pageBackward()
                }}
                sx={{
                    alignSelf: "flex-start",
                    px: '1.25rem',
                    py: '0.66rem',
                    borderColor: "black",
                    ":hover": {
                        borderColor: "black",
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                    borderRadius: "20px",
                }}
                variant="outlined"
            >
                <ArrowBackIosIcon sx={{ fontSize: '2.5rem' }} />
                TILLBAKA
            </Button>
            {children}
            <Button
                onClick={() => {
                    pageForward()
                }}
                sx={{
                    alignSelf: "flex-end",
                    visibility: visibility,
                    px: '1.25rem',
                    py: '0.66rem',
                    borderRadius: "20px",
                }}
                variant="contained"
                color="secondary"
            >
                FORTSÄTT
                <ArrowForwardIosIcon sx={{ fontSize: '2.75rem', }} />
            </Button>
        </Box>
    )
}

export default BackForwardButtonBox
