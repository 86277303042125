import MainLayout from "../Layouts/MainLayout"
import QuestionAnswerBox from "../Components/QuestionAnswerBox"
import BackForwardButtonBox from "../Components/BackForwardButtonBox"
import Question from "../Components/Question"
import AnswerSlider from "../Components/AnswerSlider"
import AnswerSelect from "../Components/AnswerSelect"

import { AnswersType } from "../Models/AnswersType"
import { ActivePage } from "../Models/ActivePageType"
import { QuestionTypes } from "../Models/QuestionTypes"
import { updateAnswer } from "../Utilities/SetNewAnswer"
import { questionsObj, QuestionType } from "../Data/questions"
import { AnswerNumber } from "../Models/AnswerNumberType"
import { evaluationStageType } from "../Models/EvaluationStageType"

interface EvaluationQuestionProps {
    activePage: ActivePage;
    questionType: QuestionTypes;
    collectedAnswers: AnswersType;
    setPageToStart: () => void;
    setPageToQuestionX: (qNum: number) => void;
    setPageToEnd: () => void;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    setQuestionType: React.Dispatch<React.SetStateAction<QuestionTypes>>;
    evaluationStage: evaluationStageType;
}

//Page for displaying the current active question, and related answer selections.
const EvaluationQuestion: React.FC<EvaluationQuestionProps> = ({
    activePage,
    questionType,
    collectedAnswers,
    setPageToStart,
    setPageToQuestionX,
    setPageToEnd,
    setCollectedAnswers,
    evaluationStage,
}) => {
    let questionToRender: number = 0
    let answersToRender: AnswerNumber = "answer1";

    //This Variable determines if the button to move foward will be visible or not. Visibility is dependent on user selecting answer. 
    let makeVisible: boolean = false

    let pageForward: () => void = () => { }
    let pageBackward: () => void = () => { }

    let questionsArr: QuestionType[];
    if (evaluationStage === "full") {
        questionsArr = questionsObj.full;

    } else {
        questionsArr = questionsObj.beforeAfter;
    }

    const generateQuestions = () => {
        questionsArr.forEach((question, index) => {
            const qNum = index + 1;
            const answerKey = `answer${qNum}` as AnswerNumber;
            const nextQuestion = qNum + 1
            const prevQuestion = qNum - 1

            switch (activePage) {
                case `question${qNum}`:
                    questionToRender = qNum
                    answersToRender = answerKey

                    const removeAnswers = { [`answer${qNum}`]: null, [`answer${qNum - 1}`]: null, };

                    const isFirstQuestion = qNum === 1;
                    const isLastQuestion = qNum === questionsArr.length;
                    const isOnlyQuestion = questionsArr.length === 1;

                    if (isFirstQuestion) {
                        pageForward = () => {
                            isOnlyQuestion ? setPageToEnd() : setPageToQuestionX(nextQuestion);
                        }
                        pageBackward = () => {
                            updateAnswer({ answer1: null }, setCollectedAnswers);
                            setPageToStart();
                        }
                    } else if (isLastQuestion) {
                        pageForward = () => { setPageToEnd() };
                        pageBackward = () => {
                            updateAnswer(removeAnswers, setCollectedAnswers);
                            setPageToQuestionX(prevQuestion);
                        }
                    } else {
                        pageForward = () => setPageToQuestionX(nextQuestion);
                        pageBackward = () => {
                            updateAnswer(removeAnswers, setCollectedAnswers);
                            setPageToQuestionX(prevQuestion);
                        }
                    }

                    makeVisible = collectedAnswers[answerKey as keyof AnswersType] !== null;

                    break;
            }
        })
    }

    generateQuestions();

    return (
        <BackForwardButtonBox makeVisible={makeVisible} pageForward={pageForward} pageBackward={pageBackward}>
            <QuestionAnswerBox>
                <Question question={questionToRender} evaluationStage={evaluationStage} />
                <AnswerSelect answerNumber={answersToRender} collectedAnswers={collectedAnswers} setCollectedAnswers={setCollectedAnswers} evaluationStage={evaluationStage} />
            </QuestionAnswerBox>
        </BackForwardButtonBox>
    )
}

export default EvaluationQuestion
