import { Answer } from "../Models/AnswerType"

import ImageJatteBra from "../Assets/Images/Smiley/jättebra.svg"
import ImageBra from "../Assets/Images/Smiley/bra.svg"
import ImageSadar from "../Assets/Images/Smiley/sådär.svg"
import ImageDaligt from "../Assets/Images/Smiley/dåligt.svg"
import ImageJatteDaligt from "../Assets/Images/Smiley/jättedåligt.svg"
import ImageTrott from "../Assets/Images/Smiley/trött.svg"
import ImagePigg from "../Assets/Images/Smiley/pigg.svg"

//These Arrays contains Data concerned with rendering certain elements on QuestionsPage,
//and Data to send to the collectedAnswers state.
export const selectConfigFull: { [key: string]: Answer[] } = {
    answer1: [
        {
            imgUrl: ImageDaligt,
            imgAlt: "smiley-dåligt",
            typographyTextContent: "DÅLIGT",
            answerData: { value: 1, description: "dåligt" }
        },
        {
            imgUrl: ImageSadar,
            imgAlt: "smiley-sådär",
            typographyTextContent: "SÅDÄR",
            answerData: { value: 2, description: "sådär" }
        },
        {
            imgUrl: ImageJatteBra,
            imgAlt: "smiley-jättebra",
            typographyTextContent: "BRA",
            answerData: { value: 3, description: "bra" }
        }
    ],
    answer2: [
        {
            imgUrl: ImageTrott,
            imgAlt: "smiley-trött",
            typographyTextContent: "TRÖTT",
            answerData: { value: 1, description: "trött" }
        },
        {
            imgUrl: ImageSadar,
            imgAlt: "smiley-sådär",
            typographyTextContent: "SÅDÄR",
            answerData: { value: 2, description: "sådär" }
        },
        {
            imgUrl: ImagePigg,
            imgAlt: "smiley-pigg",
            typographyTextContent: "PIGG",
            answerData: { value: 3, description: "pigg" }
        }
    ],
    answer3: [
        {
            imgUrl: ImageDaligt,
            imgAlt: "smiley-dåligt",
            typographyTextContent: "NEJ",
            answerData: { value: 1, description: "nej" }
        },
        {
            imgUrl: ImageSadar,
            imgAlt: "smiley-sådär",
            typographyTextContent: "KANSKE",
            answerData: { value: 2, description: "kanske" }
        },
        {
            imgUrl: ImageJatteBra,
            imgAlt: "smiley-jättebra",
            typographyTextContent: "JA",
            answerData: { value: 3, description: "ja" }
        }
    ]
}

export const selectConfigBeforeAfter: { [key: string]: Answer[] } = {
    answer1: [
        {
            imgUrl: ImageDaligt,
            imgAlt: "smiley-dåligt",
            typographyTextContent: "DÅLIGT",
            answerData: { value: 1, description: "dåligt" }
        },
        {
            imgUrl: ImageSadar,
            imgAlt: "smiley-sådär",
            typographyTextContent: "SÅDÄR",
            answerData: { value: 2, description: "sådär" }
        },
        {
            imgUrl: ImageJatteBra,
            imgAlt: "smiley-jättebra",
            typographyTextContent: "BRA",
            answerData: { value: 3, description: "bra" }
        }
    ],
}


/* export const sliderConfig: { [key: string]: Answer[] } = {
    answer1: [
        {
            imgUrl: ImageJatteDaligt,
            imgAlt: "smiley-jättedåligt",
            typographyTextContent: "JÄTTEDÅLIGT",
            answerData: { value: 1, description: "jättedåligt" }
        },
        {
            imgUrl: ImageDaligt,
            imgAlt: "smiley-dåligt",
            typographyTextContent: "DÅLIGT",
            answerData: { value: 2, description: "dåligt" }
        },
        {
            imgUrl: ImageSadar,
            imgAlt: "smiley-sådär",
            typographyTextContent: "SÅDÄR",
            answerData: { value: 3, description: "sådär" }
        },
        {
            imgUrl: ImageBra,
            imgAlt: "smiely-bra",
            typographyTextContent: "BRA",
            answerData: { value: 4, description: "bra" }
        },
        {
            imgUrl: ImageJatteBra,
            imgAlt: "smiely-jättebra",
            typographyTextContent: "JÄTTEBRA",
            answerData: { value: 5, description: "jättebra" }
        }
    ],
    answer2: [
        {
            imgUrl: ImageTrott,
            imgAlt: "smiley-trött",
            typographyTextContent: "JÄTTETRÖTT",
            answerData: { value: 1, description: "jättetrött" }
        },
        {
            imgUrl: ImageTrott,
            imgAlt: "smiley-trött",
            typographyTextContent: "TRÖTT",
            answerData: { value: 2, description: "trött" }
        },
        {
            imgUrl: ImageSadar,
            imgAlt: "smiley-sådär",
            typographyTextContent: "SÅDÄR",
            answerData: { value: 3, description: "sådär" }
        },
        {
            imgUrl: ImagePigg,
            imgAlt: "smiley-pigg",
            typographyTextContent: "PIGG",
            answerData: { value: 4, description: "pigg" }
        },
        {
            imgUrl: ImagePigg,
            imgAlt: "smiley-pigg",
            typographyTextContent: "JÄTTEPIGG",
            answerData: { value: 5, description: "jättepigg" }
        }
    ],
    answer3: [
        {
            imgUrl: ImageJatteDaligt,
            imgAlt: "smiley-jättedåligt",
            typographyTextContent: "JÄTTENEJ",
            answerData: { value: 1, description: "jättenej" }
        },
        {
            imgUrl: ImageDaligt,
            imgAlt: "smiley-dåligt",
            typographyTextContent: "NEJ",
            answerData: { value: 2, description: "nej" }
        },
        {
            imgUrl: ImageSadar,
            imgAlt: "smiley-sådär",
            typographyTextContent: "KANSKE",
            answerData: { value: 3, description: "kanske" }
        },
        {
            imgUrl: ImageBra,
            imgAlt: "smiley-bra",
            typographyTextContent: "JA",
            answerData: { value: 4, description: "ja" }
        },
        {
            imgUrl: ImageJatteBra,
            imgAlt: "smiley-jättebra",
            typographyTextContent: "JÄTTEJA",
            answerData: { value: 5, description: "jätteja" }
        }
    ]
} */
