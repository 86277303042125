import { Box, Button, Card, CardMedia, Typography } from "@mui/material"
import { flexCenterR, flexCenterC } from "../Styles/EvaluationToolTheme";
import { evaluationStageType } from "../Models/EvaluationStageType";

import { AnswersType, AnswerDataType } from "../Models/AnswersType";

interface EndContentBoxProps {
    imageA: string;
    imageB: string;
    imageAltA: string;
    imageAltB: string;
    buttonText: string;
    buttonAction: () => void;
    evaluationStage: evaluationStageType;
    setPageToAnswerIndex: () => void;
    collectedAnswers: AnswersType;
    setCollectedAnswersCopy: React.Dispatch<React.SetStateAction<AnswersType>>;
}

const cardStyles = {
    height: "100%",
    mx: 8,
    boxShadow: 'none',
}

const cardMediaStyles = {
    height: "100%",
    width: "auto",
    objectFit: "cover",
}

const EndContentBox: React.FC<EndContentBoxProps> = ({
    imageA,
    imageB,
    imageAltA,
    imageAltB,
    buttonText,
    buttonAction,
    evaluationStage,
    setPageToAnswerIndex,
    collectedAnswers,
    setCollectedAnswersCopy
}) => {

    const updateAnswers = () => {
        const answerToPush = Object.values(collectedAnswers)[0] as AnswerDataType;

        setCollectedAnswersCopy(prevState => {
            const answerNumber = Object.keys(prevState).length + 1
            const answer = `answer${answerNumber}`;
            const newState = { ...prevState, [answer]: answerToPush };

            return newState
        });
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                ...flexCenterC
            }}
        >
            <Box sx={{
                margin: "auto",
                width: "100%",
                height: "100%",
                ...flexCenterR
            }}>
                <Card
                    sx={{
                        ...cardStyles
                    }}
                >
                    <CardMedia
                        sx={{
                            ...cardMediaStyles
                        }}
                        component="img"
                        alt={imageAltA}
                        image={imageA}
                    />
                </Card>

                <Card
                    sx={{
                        ...cardStyles
                    }}
                >
                    <CardMedia
                        sx={{
                            ...cardMediaStyles
                        }}
                        component="img"
                        alt={imageAltB}
                        image={imageB}
                    />
                </Card>
            </Box>

            {evaluationStage === "full" ?
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 8,
                        padding: "1.25rem 0.66rem",
                        borderRadius: "20px",
                        height: "50%",
                    }}
                    onClick={buttonAction}
                >
                    {buttonText}
                </Button>
                :
                <>
                    <Typography
                        sx={{
                            mt: 8,
                            padding: "1.25rem 0.66rem",
                            borderRadius: "20px",
                            height: "50%",
                            backgroundColor: "grey",
                            fontSize: "2.5rem"
                        }}
                    >
                        TACK FÖR DITT SVAR!
                    </Typography>
                    <Box sx={{ height: "100%" }}>
                        <Button onClick={() => {
                            updateAnswers();
                            setPageToAnswerIndex();
                        }} variant="contained" color="secondary">Fortsätt</Button>
                        <Button onClick={() => {/* send data */ }} variant="contained" color="error">Avsluta</Button>
                    </Box>
                </>

            }
        </Box >
    )
}

export default EndContentBox