import { evaluationStageType } from "../Models/EvaluationStageType"

export interface QuestionType {
    id: number
    text: string
}

export type questionArrType = "beforeAfter" | "full";

//This Array contains the Questions for rendering on QuestionsPage.
export const questionsObj = {
    beforeAfter: [
        {
            id:1,
            text:"HUR KÄNNER DU DIG?"
        }
    ],
    full: [
        {
            id: 1,
            text: "HUR KÄNDES DET ATT SPELA IDAG?"
        },
        {
            id: 2,
            text: "HUR KÄNNER DU DIG?"
        },
        {
            id: 3,
            text: "SKULLE DU VILJA SPELA IGEN?"
        }
    ]
}

//Function for finding the .text for a specific question inside the array
export const setQuestion = (question: number | string, arrayKey: evaluationStageType ) => {
    let questionsArrToRender: questionArrType;
    questionsArrToRender = arrayKey === "before" || arrayKey === "after" ? "beforeAfter" : "full";

    if (typeof question === "number") {
        const found = questionsObj[questionsArrToRender].find((q) => q.id === question)
        return found?.text
    } else {
        return question
    }
}
