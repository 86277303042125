import { Box } from "@mui/material"
import { WithChildren } from "../Models/WithChildren"

const MainLayout: React.FC<WithChildren> = ({ children }) => {
    return (
        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
            }}
        >
            {children}
        </Box>
    )
}

export default MainLayout