import React from 'react'
import { Card, CardMedia, Typography } from '@mui/material'

import { updateAnswer } from '../Utilities/SetNewAnswer'
import { highlightSelect, diminishSelect } from "../Utilities/SetHighlightStyle"

import { AnswersType } from '../Models/AnswersType';
import { selectConfigFull, selectConfigBeforeAfter } from '../Data/answers';
import { AnswerNumber } from "../Models/AnswerNumberType"

import { flexSpaceC } from '../Styles/EvaluationToolTheme';
import { evaluationStageType } from '../Models/EvaluationStageType';

interface AnswerSelectCardProps {
    answerNumber: AnswerNumber;
    collectedAnswers: AnswersType;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    index: number;
    evaluationStage: evaluationStageType;
}

const AnswerSelectCard = ({
    answerNumber,
    collectedAnswers,
    setCollectedAnswers,
    index,
    evaluationStage,
}: AnswerSelectCardProps) => {



    let selectedAnswer;
    if (evaluationStage === "full") {
        selectedAnswer = selectConfigFull[answerNumber] || [];
    } else {
        selectedAnswer = selectConfigBeforeAfter[answerNumber] || [];
    }

    const { imgUrl: imgUrl, imgAlt: imgAlt, typographyTextContent: typographyTextContent, answerData: answerData } = selectedAnswer[index] || {}

    return (
        <Card
            sx={{
                ...flexSpaceC,
                boxShadow: "none",
                borderRadius: "none",
                height: "100%",
            }}
            onClick={() => {
                updateAnswer({ [answerNumber]: answerData }, setCollectedAnswers)
            }}
        >
            <CardMedia
                component="img"
                alt={imgAlt}
                image={imgUrl}
                className={`${highlightSelect(index + 1, collectedAnswers, answerNumber)}${diminishSelect(index + 1, collectedAnswers, answerNumber)}`}
                sx={{
                    borderRadius: "20px", py: 3, px: 2, height: "75%", cursor: "pointer"
                }}
            />
            <Typography
                variant="h2"
                color="initial"
                sx={{
                    textAlign: "center",
                }}
            >
                {typographyTextContent}
            </Typography>
        </Card>
    )
}

export default AnswerSelectCard
